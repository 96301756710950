<template>
  <div class="call-page">
    <div class="call-info">
      <h1>{{ callStatus }}</h1>
      <p class="timer" v-if="isCallActive">{{ formattedTime }}</p>
    </div>
    <div class="avatar">
      <img src="http://placekitten.com/200/200" alt="用户头像">
      <div class="ai-thinking" v-if="isAiThinking">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <div class="controls" v-if="!isCallActive">
      <button @click="startCall" class="start-call">
        <i class="fas fa-phone"></i>
      </button>
    </div>
    <div class="controls" v-else>
      <button @click="toggleMute" :class="{ active: isMuted }">
        <i class="fas" :class="isMuted ? 'fa-microphone-slash' : 'fa-microphone'"></i>
      </button>
      <button @click="endCall" class="end-call">
        <i class="fas fa-phone-slash"></i>
      </button>
      <button @click="toggleSpeaker" :class="{ active: isSpeakerOn }">
        <i class="fas" :class="isSpeakerOn ? 'fa-volume-up' : 'fa-volume-down'"></i>
      </button>
    </div>
    <audio ref="audioPlayer" :src="audioSrc" v-if="audioSrc" @error="handleAudioError" preload="auto" style="display: none;"></audio>
    <button @click="startPlay" v-if="audioQueue.length > 0 && !isPlaying" class="start-play">
      播放音频
    </button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
  data() {
    return {
      decibels: 0,
      audioContext: null,
      analyser: null,
      microphone: null,
      audioWorkletNode: null,
      mediaRecorder: null,
      isMuted: false,
      isSpeakerOn: false,
      callStatus: '准备通话',
      isCallActive: false,
      callStartTime: null,
      timer: null,
      elapsedTime: 0,
      isAiThinking: false,
      silenceTimer: null,
      lastSpeakTime: Date.now(),
      audioChunks: [],
      sampleRate: 8000,
      result: null,
      audioSrc: null,
      isRecording: false,
      showVolumeWarning: false,
      lowVolumeCounter: 0,
      threshold: -20,
      audioQueue: [],
      isPlaying: false,
      audioLock: false,
      lastAudioUrl: null,
      clientId: null,
    };
  },
  computed: {
    formattedTime() {
      const hours = Math.floor(this.elapsedTime / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((this.elapsedTime % 3600) / 60).toString().padStart(2, '0');
      const seconds = (this.elapsedTime % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  methods: {
    async startCall() {
      this.clientId = uuidv4();  // 生成并保存 clientId
      this.isCallActive = true;
      this.callStatus = '通话中';
      this.callStartTime = new Date();
      this.startTimer();
      this.initSSE();
      await this.startListening();
    },
    async startListening() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.microphone = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048;

        // 加载音频处理器模块
        const processorBlob = new Blob([`
          class AudioProcessor extends AudioWorkletProcessor {
            constructor() {
              super();
              this.port.onmessage = this.onmessage.bind(this);
            }

            onmessage(event) {
              this.threshold = event.data.threshold;
            }

            process(inputs, outputs, parameters) {
              const input = inputs[0];
              if (input.length > 0) {
                const channelData = input[0];
                let rms = 0;

                for (let i = 0; i < channelData.length; i++) {
                  rms += channelData[i] ** 2;
                }
                rms = Math.sqrt(rms / channelData.length);
                const decibels = 20 * Math.log10(rms);

                this.port.postMessage({ decibels });
              }

              return true;
            }
          }

          registerProcessor('audio-processor', AudioProcessor);
        `], {type: 'application/javascript'});
        const processorURL = URL.createObjectURL(processorBlob);
        await this.audioContext.audioWorklet.addModule(processorURL);

        this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor');

        this.audioWorkletNode.port.onmessage = (event) => {
          this.decibels = Math.round(event.data.decibels);
          this.checkUserSpeaking(this.decibels);
        };

        this.microphone.connect(this.analyser);
        this.analyser.connect(this.audioWorkletNode);
        this.audioWorkletNode.connect(this.audioContext.destination);

        this.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm'});
        this.mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = () => {
          console.log("MediaRecorder 停止，发送音频");
          this.sendAudio();
        };
      } catch (err) {
        console.error('访问麦克风出错', err);
      }
    },
    startRecording() {
      console.log('尝试开始录音');
      if (this.isRecording) {
        console.log('已经在录音，停止当前录音');
        this.stopRecording();
      }
      setTimeout(() => {
        if (this.mediaRecorder && this.mediaRecorder.state === 'inactive') {
          this.audioChunks = [];
          this.mediaRecorder.start();
          this.isRecording = true;
          this.lowVolumeCounter = 0; // 重置计数器
          this.showVolumeWarning = false; // 重置音量警告
          if (this.silenceTimer) {
            clearTimeout(this.silenceTimer);
            this.silenceTimer = null;
          }
          console.log('开始新的录音');
        } else {
          console.log('无法开始录音，MediaRecorder 不可用或状态不正确');
        }
      }, 100);
    },
    stopRecording() {
      console.log('尝试停止录音');
      if (this.isRecording && this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop();
        this.isRecording = false;
        if (this.silenceTimer) {
          clearTimeout(this.silenceTimer);
          this.silenceTimer = null;
        }
        console.log('停止录音');
      } else {
        console.log('无法停止录音，当前没有进行中的录音');
      }
    },
    checkUserSpeaking(decibels) {
      const threshold = this.threshold; // 降低阈值，使其更容易触发
      if (decibels >= threshold) {
        this.lastSpeakTime = Date.now();
        this.showVolumeWarning = false;
        this.lowVolumeCounter = 0;
        if (this.isAiThinking) {
          this.isAiThinking = false;
        }
        if (this.silenceTimer) {
          clearTimeout(this.silenceTimer);
          this.silenceTimer = null;
        }
        if (!this.isRecording) {
          console.log("检测到声音，开始录音");
          this.startRecording();
        }
      } else {
        this.lowVolumeCounter++;
        if (!this.silenceTimer && this.isRecording) {
          this.silenceTimer = setTimeout(() => {
            console.log("检测到持续静音，停止录音");
            this.isAiThinking = true;
            this.stopRecording();
          }, 1000); // 等待1秒后再停止录音
        }
      }
    },
    async sendAudio() {
      console.log("调用sendAudio");
      console.log("音频块数量:", this.audioChunks.length);
      if (this.audioChunks.length > 0) {
        const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav'}); // 确保使用WAV格式
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = async () => {
          const base64Audio = reader.result.split(',')[1];
          const requestBody = JSON.stringify({prompt: base64Audio});
          try {
            const response = await axios.post(`https://shuimeisisi.cn/api/eb_stream/${this.clientId}`, requestBody, {
              headers: {'Content-Type': 'application/json'},
            });
            console.log("收到语音识别响应", response.headers['content-type']);
            console.log("响应数据:", response.data);
            this.isAiThinking = false;
          } catch (err) {
            console.error('发送音频到服务器时出错', err);
            this.result = '识别失败：' + err.message;
          }
        };
      } else {
        console.log("没有音频数据可发送");
      }
    },
    initSSE() {
      const eventSource = new EventSource(`https://shuimeisisi.cn/api/eb_stream/${this.clientId}`);
      eventSource.onopen = () => {
        console.log('SSE 连接已打开');
      };
      eventSource.onmessage = async (event) => {
        console.log('SSE 消息接收:', event);
        const base64Audio = event.data;
        const audioBlob = this.base64ToBlob(base64Audio, 'audio/wav');
        try {
          const audioBuffer = await this.audioContext.decodeAudioData(await audioBlob.arrayBuffer());
          this.audioQueue.push(audioBuffer);
          if (!this.isPlaying) {
            this.playNextInQueue();
          }
        } catch (error) {
          console.error('解码音频数据失败:', error);
        }
      };
      eventSource.onerror = (error) => {
        console.error('接收音频流时出错:', error);
        console.log('正在重新连接...');
        eventSource.close();
        // 可以在这里添加重连逻辑
      };
    },
    async playNextInQueue() {
      if (this.isPlaying || this.audioQueue.length === 0) {
        return;
      }
      this.isPlaying = true;
      const audioBuffer = this.audioQueue.shift();
      await this.playAudioBuffer(audioBuffer);
      this.isPlaying = false;
      this.playNextInQueue();
    },
    async playAudioBuffer(buffer) {
      return new Promise((resolve) => {
        this.source = this.audioContext.createBufferSource();
        this.source.buffer = buffer;
        this.source.connect(this.audioContext.destination);
        this.source.onended = () => {
          resolve();
        };
        this.source.start(0);
      });
    },
    base64ToBlob(base64, mime) {
      const byteString = atob(base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([intArray], {type: mime});
    },
    handleAudioError(e) {
      console.error('音频错误:', e);
      console.log('音频源:', this.audioSrc);
      console.log('音频元素:', this.$refs.audioPlayer);
    },
    startPlay() {
      if (!this.isPlaying && !this.audioLock) {
        this.playNextInQueue();
      }
    },
    stopListening() {
      this.stopRecording();
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
      this.decibels = 0;
      if (this.silenceTimer) {
        clearTimeout(this.silenceTimer);
        this.silenceTimer = null;
      }
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      // 这里可以添加实际的静音逻辑
    },
    toggleSpeaker() {
      this.isSpeakerOn = !this.isSpeakerOn;
      // 这里可以添加实际的扬声器切换逻辑
    },
    endCall() {
      this.stopListening();
      this.stopRecording(); // 确保在挂断电话时停止录音
      clearInterval(this.timer);
      this.isCallActive = false;
      this.callStatus = '通话结束';
      this.callStartTime = null;
      this.elapsedTime = 0;
      this.isAiThinking = false;
      this.closeSSEConnection();
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime++;
      }, 1000);
    },
    async closeSSEConnection() {
      try {
        await axios.post(`https://shuimeisisi.cn/api/close_sse/${this.clientId}`);
        console.log('SSE 连接已关闭');
      } catch (err) {
        console.error('关闭 SSE 连接时出错', err);
      }
    }
  },
  beforeDestroy() {
    this.stopListening();
    clearInterval(this.timer);
    this.closeSSEConnection();
  },
};
</script>

<style scoped>
.call-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  padding: 20px;
}

.call-info {
  text-align: center;
}

h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.timer {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* 调整此处的值来增加或减少间距 */
}

button {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 10px; /* 调整此处的值来增加或减少按钮之间的间距 */
  cursor: pointer;
  transition: all 0.3s ease;
}

button i {
  color: white;
  font-size: 24px;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

button.active {
  background-color: rgba(255, 255, 255, 0.4);
}

.end-call {
  background-color: #ff4757;
}

end-call:hover {
  background-color: #ff6b6b;
}

.start-call {
  background-color: #4CAF50;
}

.start-call:hover {
  background-color: #45a049;
}

.start-play {
  background-color: #1E90FF;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-top: 20px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-thinking {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
